import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import APIKeyRenderer from "./APIKeyRenderer"; // Ensure you have this component

const UploadModel = () => {
  const [modelName, setModelName] = useState("");
  const [modelDescription, setModelDescription] = useState("");
  const [modelFile, setModelFile] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic
  };

  return (
    <div className="container mt-4">
      <h2>Upload Model online</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">Model Name:</label>
          <input
            type="text"
            className="form-control"
            value={modelName}
            onChange={(e) => setModelName(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Model Description:</label>
          <textarea
            className="form-control"
            value={modelDescription}
            onChange={(e) => setModelDescription(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Upload Pickle File:</label>
          <input
            type="file"
            className="form-control"
            accept=".pkl"
            onChange={(e) => setModelFile(e.target.files[0])}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Upload
        </button>
      </form>
      <div className="mt-4">
        <h2>
          If you prefer, use this to upload models and datasets from scripts:
        </h2>
        <APIKeyRenderer apiKey="your-api-key-here" />
      </div>
    </div>
  );
};

export default UploadModel;
