import React, { useState } from "react";
import Model from "./Model"; // Ensure you have this component
import Dataset from "./Dataset"; // Ensure you have this component

const UserPortalCreation = () => {
  const [modelDetails, setModelDetails] = useState(null);
  const [datasetDetails, setDatasetDetails] = useState(null);

  const isButtonDisabled =
    !modelDetails ||
    !datasetDetails ||
    modelDetails === "upload" ||
    datasetDetails === "upload";
  console.log(
    "modelDetails: ",
    modelDetails,
    " datasetDetails: ",
    datasetDetails
  );
  return (
    <div className="demo-container">
      <h1>Create New User Portals</h1>
      <h2>Create new User Portals to interact with predictive models</h2>
      <div className="row">
        <div className="col-md-6">
          <h2>Model Selection</h2>
          <Model onModelChange={setModelDetails} />
        </div>
        <div className="col-md-6">
          <h2>Dataset Selection</h2>
          <Dataset onDatasetChange={setDatasetDetails} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 text-right">
          <button className="btn btn-primary" disabled={isButtonDisabled}>
            Create Portal
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserPortalCreation;
