import React from "react";
import SpeechBubble from "../../home/SpeechBubble";
import "bootstrap/dist/css/bootstrap.min.css";
import "./ChatPortal.css";
import { useChat } from "./ChatContext";

const ChatPortal = () => {
  const { messages } = useChat(); // Access chat messages from context

  return (
    <div>
      {messages.map((message, index) => (
        <SpeechBubble key={index} text={message.text} from={message.from} />
      ))}
    </div>
  );
};

export default ChatPortal;
