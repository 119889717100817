import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./SpeechBubble.css";

const SpeechBubble = React.memo(({ text, from, onComplete }) => {
  const [displayedText, setDisplayedText] = useState("");
  const [index, setIndex] = useState(0);
  const completed = useRef(false); // Track completion to ensure `onComplete` fires only once

  useEffect(() => {
    if (from.toLowerCase() === "user") {
      setDisplayedText(text);
      if (!completed.current && onComplete) {
        completed.current = true; // Ensure `onComplete` fires only once
        console.log("SpeechBubble: calling onComplete");
        onComplete();
      }
    } else {
      if (index < text.length) {
        const timeout = setTimeout(() => {
          setDisplayedText((prev) => prev + text[index]);
          setIndex(index + 1);
        }, 10);
        return () => clearTimeout(timeout);
      } else if (!completed.current && onComplete) {
        completed.current = true; // Ensure `onComplete` fires only once
        console.log("SpeechBubble: calling onComplete");
        onComplete();
      }
    }
  }, [index, text, from, onComplete]);

  const className = from.toLowerCase();

  return (
    <div className={`card speech-bubble ${className}`}>
      <div className="card-body">
        <h5 className={`card-title ${className}`}>{from}</h5>
        <p className="card-text">{displayedText}</p>
      </div>
    </div>
  );
});

export default SpeechBubble;
