import React, { useState, useContext } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { CollectionContext } from './CollectionContext';


const SideBar = ({ setSelectedTool }) => {
const { collections } = useContext(CollectionContext);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };
  const selectedCollection = collections.find((collection) => collection.selected);
  return (
    <div className={isCollapsed ? 'col-md-1 bg-light border-right vh-100 d-flex flex-column' : 'col-md-2 bg-light border-right vh-100 d-flex flex-column'}>
      <button className="btn btn-secondary m-2" onClick={toggleSidebar}>
        {isCollapsed ? 'Expand' : 'Collapse'}
      </button>
      {!isCollapsed && (
        <>
          {/* Top Section - Model Information */}
          <div className="p-3 border-bottom flex-shrink-0">
            <h5 className="mb-3">Model Information</h5>
            <p className="mb-1"><strong>Name:</strong> churn_prevention</p>
            <p className="mb-1"><strong>Version:</strong> v3.42</p>
            <p><strong>Last Updated:</strong>13/01/2024</p>
          </div>

          {/* Middle Section - Navigation */}
          <div className="p-3 border-bottom flex-shrink-0">
            <h5 className="mb-3">Tools</h5>
            <ul className="list-unstyled">
              <li>
                <button className="btn btn-navi text-decoration-none" onClick={() => setSelectedTool('insights')}>🧑‍🧑‍🧒‍🧒 Understand Customers</button>
              </li>
              <li>
                <button className="btn btn-navi text-decoration-none" onClick={() => setSelectedTool('intervention-planner')}>📈 Optimise Outcomes</button>
              </li>
            </ul>
          </div>

          {/* Bottom Section - Segment Management */}
          <div className="p-3 flex-grow-1">
            <h5 className="mb-3">Segment Management</h5>
            <p className="mb-2"><strong>Current : {selectedCollection.name}</strong></p>
                   <button className="btn btn-custom" onClick={() => setSelectedTool('manage-segments')}>🛠️ Manage Segments</button>
            </div>
        </>
      )}
    </div>
  );
};

export default SideBar;