import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "./About.css"; // Ensure you import the CSS file

const About = () => {
    return (
        <div className="about">
            <div className="container my-5">
                <div className="row">
                    <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
                        <h2 className="text-center">Understanding and preventing customer churn is hard, lets do it together</h2>
                        <p className="text-center">Our team has a blend of data and business expertise which places them uniquely to solve this issue</p>
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-center">
                        <img src="img/tortySmiling.jpg" alt="Smiling happily" className="img-fluid grid" />
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-6 d-flex align-items-center justify-content-center">
                        <img src="img/alexisSmiling.jpg" alt="Bottom Left 2" className="img-fluid grid" />
                    </div>
                    <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
                        <h2 className="text-center">Our platform simplifies customer insights</h2>
                        <p className="text-center">Our platform uses advanced analytics and machine learning to provide actionable insights into customer behavior, helping you understand and reduce churn effectively.</p>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-12">
                        <h2 className="text-center">Our Backers</h2>
                        <div className="table-responsive">
                            <table className="table table-borderless text-center mt-3">
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                                                <div className="p-2">
                                                    <img src="img/OxbridgeAI.png" alt="Oxbridge AI Challenge Logo" className="img-fluid backer" />
                                                </div>
                                                {/* <div className="p-2">
                                                    <img src="img/WomenTechEU.png" alt="Women In Tech EU Logo" className="img-fluid backer" />
                                                </div> */}
                                                <div className="p-2">
                                                    <img src="img/runway.jpeg" alt="Runway Logo" className="img-fluid backer" />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;