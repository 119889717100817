import React, { useState, useContext } from 'react';
import { CollectionContext } from './CollectionContext';
import {
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { useChat } from './ChatContext';

const InterventionPlanner = () => {
  const data = [
    {
      "Total Spend": 500,
      "Gender": "Male",
      "Payment Delay": 5,
      "Last Interaction": "2023-01-15",
      "Contract Length_Monthly": 6,
      "Subscription Type_Basic": true,
      "Subscription Type_Standard": false,
      "Contract Length_Annual": 2,
      "Age": 30,
      "Usage Frequency": 50,
      "Contract Length_Quarterly": 1,
      "Tenure": 3,
      "Subscription Type_Premium": false,
    },
    {
      "Total Spend": 750,
      "Gender": "Female",
      "Payment Delay": 10,
      "Last Interaction": "2023-02-20",
      "Contract Length_Monthly": 12,
      "Subscription Type_Basic": false,
      "Subscription Type_Standard": true,
      "Contract Length_Annual": 3,
      "Age": 25,
      "Usage Frequency": 70,
      "Contract Length_Quarterly": 2,
      "Tenure": 5,
      "Subscription Type_Premium": false,
    },
    {
      "Total Spend": 300,
      "Gender": "Male",
      "Payment Delay": 0,
      "Last Interaction": "2023-03-10",
      "Contract Length_Monthly": 3,
      "Subscription Type_Basic": true,
      "Subscription Type_Standard": false,
      "Contract Length_Annual": 1,
      "Age": 40,
      "Usage Frequency": 30,
      "Contract Length_Quarterly": 1,
      "Tenure": 2,
      "Subscription Type_Premium": false,
    },
    {
      "Total Spend": 600,
      "Gender": "Female",
      "Payment Delay": 15,
      "Last Interaction": "2023-04-05",
      "Contract Length_Monthly": 9,
      "Subscription Type_Basic": false,
      "Subscription Type_Standard": true,
      "Contract Length_Annual": 4,
      "Age": 35,
      "Usage Frequency": 60,
      "Contract Length_Quarterly": 3,
      "Tenure": 4,
      "Subscription Type_Premium": true,
    },
    {
      "Total Spend": 450,
      "Gender": "Male",
      "Payment Delay": 20,
      "Last Interaction": "2023-05-25",
      "Contract Length_Monthly": 6,
      "Subscription Type_Basic": true,
      "Subscription Type_Standard": false,
      "Contract Length_Annual": 2,
      "Age": 28,
      "Usage Frequency": 40,
      "Contract Length_Quarterly": 2,
      "Tenure": 3,
      "Subscription Type_Premium": false,
    },
    {
      "Total Spend": 800,
      "Gender": "Female",
      "Payment Delay": 25,
      "Last Interaction": "2023-06-15",
      "Contract Length_Monthly": 12,
      "Subscription Type_Basic": false,
      "Subscription Type_Standard": true,
      "Contract Length_Annual": 5,
      "Age": 45,
      "Usage Frequency": 80,
      "Contract Length_Quarterly": 4,
      "Tenure": 6,
      "Subscription Type_Premium": true,
    },
    {
      "Total Spend": 550,
      "Gender": "Male",
      "Payment Delay": 5,
      "Last Interaction": "2023-07-10",
      "Contract Length_Monthly": 6,
      "Subscription Type_Basic": true,
      "Subscription Type_Standard": false,
      "Contract Length_Annual": 3,
      "Age": 32,
      "Usage Frequency": 50,
      "Contract Length_Quarterly": 1,
      "Tenure": 4,
      "Subscription Type_Premium": false,
    },
    {
      "Total Spend": 700,
      "Gender": "Female",
      "Payment Delay": 10,
      "Last Interaction": "2023-08-20",
      "Contract Length_Monthly": 9,
      "Subscription Type_Basic": false,
      "Subscription Type_Standard": true,
      "Contract Length_Annual": 4,
      "Age": 38,
      "Usage Frequency": 70,
      "Contract Length_Quarterly": 3,
      "Tenure": 5,
      "Subscription Type_Premium": true,
    },
    {
      "Total Spend": 400,
      "Gender": "Male",
      "Payment Delay": 0,
      "Last Interaction": "2023-09-05",
      "Contract Length_Monthly": 3,
      "Subscription Type_Basic": true,
      "Subscription Type_Standard": false,
      "Contract Length_Annual": 1,
      "Age": 29,
      "Usage Frequency": 30,
      "Contract Length_Quarterly": 1,
      "Tenure": 2,
      "Subscription Type_Premium": false,
    },
    {
      "Total Spend": 650,
      "Gender": "Female",
      "Payment Delay": 15,
      "Last Interaction": "2023-10-25",
      "Contract Length_Monthly": 12,
      "Subscription Type_Basic": false,
      "Subscription Type_Standard": true,
      "Contract Length_Annual": 5,
      "Age": 42,
      "Usage Frequency": 60,
      "Contract Length_Quarterly": 4,
      "Tenure": 6,
      "Subscription Type_Premium": true,
    },
  ];
  const chosenFeatures = [];

  function filterData(column, value) {
    return data.filter(row => row[column] === value);
  }
  const [selectedSegment, setSelectedSegment] = useState("All Customers");
  const { collections } = useContext(CollectionContext);
  const selectedCollection = collections.find((collection) => collection.selected);
  const { dispatch } = useChat(); // Access the chat context

  const handleToggleChange = (e) => {
    const value = e.currentTarget.value;
    setSelectedSegment(value);
    dispatch({
      type: "ADD_MESSAGE",
      payload: {
        text: 'You have selected ' + value,
        from: "decima2",
      },
    })
  };

  const filteredData = Object.keys(data[selectedSegment])
    .reduce((obj, key) => {
      obj[key] = data[selectedSegment][key];
      return obj;
    }, {});

   return (
    <div>
      <h5 className="mt-3 mb-3">📈 Optimise Outcomes</h5>
      <h5>1. Who are we optimising outcomes for?</h5>
      <div className="d-flex justify-content-center">
        <ButtonGroup className="btn-group">
          <ToggleButton
            key="All Customers"
            id="subgroup-all-customers"
            type="radio"
            name="subgroup"
            value="All Customers"
            checked={selectedSegment === "All Customers"}
            onChange={handleToggleChange}
            className="btn-custom"
          >
            All Customers
          </ToggleButton>
          {selectedCollection.segments.map((subgroup) => (
            <ToggleButton
              key={subgroup}
              id={`subgroup-${subgroup}`}
              type="radio"
              name="subgroup"
              value={subgroup}
              checked={selectedSegment === subgroup}
              onChange={handleToggleChange}
              className="btn-custom"
            >
              {subgroup}
            </ToggleButton>
          ))}

        </ButtonGroup>
      </div>
      <h5>2. What can we optimise?</h5>

      <table>
        <thead>
          <tr>
            {Object.keys(data[0]).map((key) => (
              <th key={key}>{key}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {Object.values(row).map((feature, index) => (
                <td
                  key={index}
                  style={{
                    cursor: 'pointer'
                  }}
                >
                  {feature.toString()}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <h5>3. Changes required to reduce churn</h5>
      <p>In order to have the greatest reduction in churn for {selectedSegment},  {chosenFeatures.length == 0 ? "please choose something we can change" : "if we are able to change " + chosenFeatures.join(', ') + " here is the best way: "} </p>
      <div className="row align-items-center mb-3">
        <div className="col-md-6 text-st">
        </div>
      </div>
      <p>Currently there is 5712 in {selectedSegment} and 67 are predicted to churn.  </p>
      <p>{chosenFeatures.length == 0 ? "" : "If you Reduce " + chosenFeatures[0] + " by 3.6 for each customer in " + selectedSegment + ". This will enable you to retain 15 more customers."}</p>
      <div className="row align-items-center mb-3">

        {/* <Bar height={75} data={chartData} options={options} /> */}

      </div>
    </div>
  );
};

export default InterventionPlanner;