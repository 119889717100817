import React, { useState, useEffect } from "react";
import { Navbar, Container, Nav, Button } from "react-bootstrap";
import { auth } from "./firebase"; // Import Firebase auth
import { signOut } from "firebase/auth"; // Import signOut function
import "bootstrap/dist/css/bootstrap.min.css";
import "./NavBar.css";

const CustomNavbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsLoggedIn(!!user);
    });
    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error logging out: ", error);
    }
  };

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        {/* Text Logo */}
        <Navbar.Brand href="/">
          <div id="logo">DECIMA2</div>
        </Navbar.Brand>
        {/* Burger Menu Button */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {/* Navbar Links */}
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="/blog">Blog</Nav.Link>
            <Nav.Link href="/about">About</Nav.Link>

            <Nav.Link href="/docs">API Docs</Nav.Link>
            <Nav.Link href="/demo">Demo</Nav.Link>
            {isLoggedIn && (
              <Button
                variant="outline-danger"
                onClick={handleLogout}
                className="ms-3"
              >
                Logout
              </Button>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
