import React, { useState, useContext } from "react";
import { Bar } from "react-chartjs-2";
import {
    Chart,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import {
    ButtonGroup,
    ToggleButton,
    DropdownButton,
    Dropdown,
    Form,
    Button
} from "react-bootstrap";
import "./DashboardButtons.css";
import { useChat } from './ChatContext';
import { CollectionContext } from './CollectionContext';
// Register Chart.js components
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Insights = () => {
    const featureImportanceData = {
        "All Customers": { "Gender": 0.08750000000000002, "Churn": 0, "Subscription Type_Basic": 0.05500000000000005, "Subscription Type_Premium": 0.05249999999999999, "Subscription Type_Standard": 0.050000000000000044, "Contract Length_Annual": 0.05500000000000005, "Contract Length_Monthly": 0.08250000000000002, "Contract Length_Quarterly": 0.05249999999999999, "Age": 0.12250000000000005, "Tenure": 0.057499999999999996, "Usage Frequency": 0.050000000000000044, "Support Calls": 0.21250000000000002, "Payment Delay": 0.11750000000000005, "Total Spend": 0.14749999999999996, "Last Interaction": 0.07250000000000001 }
        ,
        "Male": {
            "Support Calls": 0.2065217391304348,
            "Total Spend": 0.2065217391304348,
            "Gender": 0.17391304347826086,
            "Payment Delay": 0.13043478260869568,
            "Last Interaction": 0.10869565217391308,
            "Contract Length_Monthly": 0.08695652173913049,
            "Subscription Type_Basic": 0.06521739130434778,
            "Subscription Type_Standard": 0.06521739130434778,
            "Contract Length_Annual": 0.06521739130434778,
            "Age": 0.06521739130434778,
            "Usage Frequency": 0.8521739130434778,
            "Contract Length_Quarterly": 0.05434782608695654,
            "Tenure": 0.05434782608695654,
            "Subscription Type_Premium": 0.03260869565217395,
            "Churn": 0
        },
        "Female": {
            "Support Calls": 0.2065217391304348,
            "Total Spend": 0.2065217391304348,
            "Gender": 0.17391304347826086,
            "Payment Delay": 0.13043478260869568,
            "Last Interaction": 0.70869565217391308,
            "Contract Length_Monthly": 0.08695652173913049,
            "Subscription Type_Basic": 0.06521739130434778,
            "Subscription Type_Standard": 0.06521739130434778,
            "Contract Length_Annual": 0.06521739130434778,
            "Age": 0.06521739130434778,
            "Usage Frequency": 0.06521739130434778,
            "Contract Length_Quarterly": 0.05434782608695654,
            "Tenure": 0.05434782608695654,
            "Subscription Type_Premium": 0.03260869565217395,
            "Churn": 0
        },
        "Other": {
            "Support Calls": 0.2065217391304348,
            "Total Spend": 0.2065217391304348,
            "Gender": 0.57391304347826086,
            "Payment Delay": 0.13043478260869568,
            "Last Interaction": 0.10869565217391308,
            "Contract Length_Monthly": 0.08695652173913049,
            "Subscription Type_Basic": 0.06521739130434778,
            "Subscription Type_Standard": 0.06521739130434778,
            "Contract Length_Annual": 0.06521739130434778,
            "Age": 0.06521739130434778,
            "Usage Frequency": 0.06521739130434778,
            "Contract Length_Quarterly": 0.05434782608695654,
            "Tenure": 0.05434782608695654,
            "Subscription Type_Premium": 0.03260869565217395,
            "Churn": 0
        },
        "18.0 >= Age < 26.0": {
            "Support Calls": 0.2065217391304348,
            "Total Spend": 0.2065217391304348,
            "Gender": 0.17391304347826086,
            "Payment Delay": 0.13043478260869568,
            "Last Interaction": 0.10869565217391308,
            "Contract Length_Monthly": 0.08695652173913049,
            "Subscription Type_Basic": 0.06521739130434778,
            "Subscription Type_Standard": 0.06521739130434778,
            "Contract Length_Annual": 0.06521739130434778,
            "Age": 0.06521739130434778,
            "Usage Frequency": 0.06521739130434778,
            "Contract Length_Quarterly": 0.05434782608695654,
            "Tenure": 0.05434782608695654,
            "Subscription Type_Premium": 0.03260869565217395,
            "Churn": 0
        },
        "26.0 >= Age < 35.0": {
            "Gender": 0.2755102040816326,
            "Support Calls": 0.20408163265306123,
            "Total Spend": 0.15306122448979587,
            "Payment Delay": 0.09183673469387754,
            "Contract Length_Monthly": 0.0714285714285714,
            "Last Interaction": 0.061224489795918324,
            "Tenure": 0.04081632653061229,
            "Usage Frequency": 0.04081632653061229,
            "Subscription Type_Basic": 0.020408163265306145,
            "Subscription Type_Premium": 0.010204081632653073,
            "Subscription Type_Standard": 0.010204081632653073,
            "Contract Length_Annual": 0.010204081632653073,
            "Contract Length_Quarterly": 0.010204081632653073,
            "Age": 0.010204081632653073,
            "Churn": 0
        },
        "35.0 >= Age < 42.0": {
            "Gender": 0.26190476190476186,
            "Support Calls": 0.16666666666666663,
            "Payment Delay": 0.13095238095238093,
            "Last Interaction": 0.11904761904761907,
            "Total Spend": 0.09523809523809523,
            "Contract Length_Monthly": 0.08333333333333337,
            "Subscription Type_Standard": 0.059523809523809534,
            "Contract Length_Annual": 0.04761904761904767,
            "Tenure": 0.04761904761904767,
            "Subscription Type_Basic": 0.0357142857142857,
            "Subscription Type_Premium": 0.0357142857142857,
            "Age": 0.0357142857142857,
            "Usage Frequency": 0.0357142857142857,
            "Contract Length_Quarterly": 0.023809523809523836,
            "Churn": 0
        },
        "35.0 >= Age < 42.0": {
            "Support Calls": 0.2637362637362637,
            "Gender": 0.23076923076923073,
            "Total Spend": 0.13186813186813184,
            "Contract Length_Monthly": 0.07692307692307687,
            "Last Interaction": 0.05494505494505497,
            "Subscription Type_Premium": 0.04395604395604391,
            "Subscription Type_Basic": 0.03296703296703296,
            "Contract Length_Annual": 0.03296703296703296,
            "Subscription Type_Standard": 0.02197802197802201,
            "Contract Length_Quarterly": 0.02197802197802201,
            "Age": 0.02197802197802201,
            "Payment Delay": 0.02197802197802201,
            "Tenure": 0.01098901098901095,
            "Usage Frequency": 0.01098901098901095,
            "Churn": 0
        },
        "50.0 >= Age < 65.0": {
            "Total Spend": 0.05882352941176472,
            "Contract Length_Monthly": 0.04705882352941182,
            "Contract Length_Annual": 0.03529411764705881,
            "Payment Delay": 0.03529411764705881,
            "Subscription Type_Premium": 0.02352941176470591,
            "Subscription Type_Standard": 0.02352941176470591,
            "Subscription Type_Basic": 0.01176470588235301,
            "Contract Length_Quarterly": 0.01176470588235301,
            "Tenure": 0.01176470588235301,
            "Usage Frequency": 0.01176470588235301,
            "Last Interaction": 0.01176470588235301,
            "Gender": 0,
            "Churn": 0,
            "Age": 0,
            "Support Calls": -0.03529411764705881
        }
    }
    const [selectedSubgroup, setSelectedSubgroup] = useState("All Customers");
    const [selectedFeatures, setSelectedFeatures] = useState([]);
    const [showInterventionBox, setShowInterventionBox] = useState(false);
    const [interventionCost, setInterventionCost] = useState(0);
    const [interventionValue, setInterventionValue] = useState(0);
    const { collections } = useContext(CollectionContext);
    const selectedCollection = collections.find((collection) => collection.selected);

    const { dispatch } = useChat(); // Access the chat context

    const subgroups = Object.keys(featureImportanceData);
    const features = Object.keys(featureImportanceData[selectedSubgroup]);
    // Update actionable features
    const handleActionableFeatureChange = (feature) => {
        setShowInterventionBox(true);
        setSelectedFeatures((prevSelectedFeatures) =>
            prevSelectedFeatures.includes(feature)
                ? prevSelectedFeatures.filter((f) => f !== feature)
                : [...prevSelectedFeatures, feature]
        );
    };
    const data = Object.values(featureImportanceData[selectedSubgroup]);

    // Prepare chart data
    const chartData = {
        labels: features,
        datasets: [
            {
                label: "Feature Importance",
                data: data,
                backgroundColor: features.map((feature) =>
                    selectedFeatures.includes(feature)
                        ? "rgba(255, 99, 132, 0.6)"
                        : "rgba(75, 192, 192, 0.6)"
                ),
                borderColor: features.map((feature) =>
                    selectedFeatures.includes(feature)
                        ? "rgba(255, 99, 132, 1)"
                        : "rgba(75, 192, 192, 1)"
                ),
                borderWidth: 1,
            },
        ],
    };

    const handleToggleChange = (e) => {
        const value = e.currentTarget.value;
        setSelectedSubgroup(value);
        dispatch({
            type: "ADD_MESSAGE",
            payload: {
                text: 'You have selected ' + value,
                from: "decima2",
            },
        })
    };
    const getChurnRate = (segment) => {
        // Generate a random number between 0.6 and 1
        const randomChurnRate = Math.random() * (1 - 0.6) + 0.6;
        // Convert to percentage
        return (randomChurnRate * 100).toFixed(2);
    };
    const [message, setMessage] = useState('');

    const options = {
        onClick: (event, elements) => {
            if (elements && elements.length > 0) {
                // Access the index of the clicked bar
                const index = elements[0].index;
                if (index !== undefined) {
                    const feature = features[index]; // Use the index to get the corresponding feature
                    setMessage(`The higher the ${feature}, for ${selectedSubgroup} the more likely the customer is to churn.`);
                }
            }
        },
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: "Feature Importance",
            },
        },
    };
    const getSegmentSize = (segment) => {
        // Generate a random number between 0.6 and 1
        const randomChurnRate = Math.random() * (1 - 0.6) + 0.6;
        // Convert to percentage and round to the nearest whole number
        return Math.round(randomChurnRate * 1000, 0);
    };  const getChurnerSize = (segment) => {
        // Generate a random number between 0.6 and 1
        const randomChurnRate = Math.random() * (1 - 0.6) ;
        // Convert to percentage and round to the nearest whole number
        return Math.round(randomChurnRate * 1000, 0);
    };

    return (
        <div>
            <h5 className="mt-3 mb-3">🧑‍🧑‍🧒‍🧒 Understand Customers</h5>
            <p>Explore what affects customers churn predictions</p>
            <div className="mb-2">
                <div className="row align-items-center mb-3">
                    <div className=" text-st">
                        <h5>Assess churn predictions across segments:</h5>
                        <div className="mt-3">
                            <table className="table">
                                <thead>
                                    <tr><th></th>
                                        <th>All Customers </th>
                                        {selectedCollection.segments.map((segment) => (
                                            <th key={segment}>{segment}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr><td>Number of churners</td>
                                        <td>{getChurnerSize("All Customers")}</td>
                                        {selectedCollection.segments.map((segment) => (
                                            <td key={segment}>{getChurnerSize(segment)}</td>
                                        ))}
                                    </tr>
                                    <tr><td>Segment size</td>
                                        <td>{getSegmentSize("All Customers")}</td>
                                        {selectedCollection.segments.map((segment) => (
                                            <td key={segment}>{getSegmentSize(segment)}</td>
                                        ))}
                                    </tr> <tr><td>Churn rate</td>
                                        <td>{getChurnRate("All Customers")}%</td>
                                        {selectedCollection.segments.map((segment) => (
                                            <td key={segment}>{getChurnRate(segment)}%</td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <h5 className="mt-1">Feature Importance</h5>
                        <p>The feature importance helps you to understand which features impact the model, making it a good place to start selecting features to intervene</p>                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <ButtonGroup className="btn-group"><ToggleButton
                        key="All Customers"
                        id="subgroup-all-customers"
                        type="radio"
                        name="subgroup"
                        value="All Customers"
                        checked={selectedSubgroup === "All Customers"}
                        onChange={handleToggleChange}
                        className="btn-custom"
                    >
                        All Customers
                    </ToggleButton>
                        {selectedCollection.segments.map((subgroup) => (
                            <ToggleButton
                                key={subgroup}
                                id={`subgroup-${subgroup}`}
                                type="radio"
                                name="subgroup"
                                value={subgroup}
                                checked={selectedSubgroup === subgroup}
                                onChange={handleToggleChange}
                                className="btn-custom"
                            >
                                {subgroup}
                            </ToggleButton>
                        ))}
                    </ButtonGroup>
                </div>
            </div >

            
            <Bar height={75} data={chartData} options={options} />
            <p>{message}</p>


            {/* <div className="row">
        <div className="col-md-4">
          <h2>Select Actionable Features:</h2>
          <DropdownButton
            id="dropdown-actionable"
            title="Actionable Features"
            variant="primary"
          >
            {features.map((feature) => (
              <Dropdown.Item
                key={feature}
                active={selectedFeatures.includes(feature)}
                onClick={() => handleActionableFeatureChange(feature)}
              >
                {feature}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </div>
        <div className="col-md-4">
          <h2>Intervention Cost:</h2>
          <Form.Control type="number" placeholder="Enter cost"
            onChange={(e) => setInterventionCost(e.target.value)} />
        </div>
        <div className="col-md-4">
          <h2>Value of successful intervention:</h2>
          <Form.Control type="number" placeholder="Enter Value" onChange={(e) => setInterventionValue(e.target.value)} />
        </div>
      </div> */}

            {/* {showInterventionBox && (
        <div className="intervention-box shadow p-3 mt-3 bg-white border">
          <h2>Intervention Settings</h2>
          <table className="table">
            <thead>
              <tr>
                <th>SubGroup</th>
                <th>Actionable Features</th>
                <th>Intervention Cost</th>
                <th>Intervention Value </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{selectedSubgroup}</td>
                <td>{selectedFeatures.join(", ")}</td>
                <td>{interventionCost}</td>
                <td>{interventionValue}</td>
              </tr>
            </tbody>
          </table>
          <Button variant="primary" className=" bottom-0 end-0 m-1">
            Find optimal intervention
          </Button>
        </div>
      )} */}
        </div>
    );
}
export default Insights;
