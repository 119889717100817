import React, { createContext, useReducer, useContext } from "react";

const ChatContext = createContext();

const chatReducer = (state, action) => {
  switch (action.type) {
    case "ADD_MESSAGE":
      return [...state, action.payload];
    case "CLEAR_MESSAGES":
      return [];
    default:
      throw new Error("Unknown action type");
  }
};

export const ChatProvider = ({ children }) => {
  const [state, dispatch] = useReducer(chatReducer, [
    {
      text: "Welcome to Decima2! We will help you make the best decisions about reducing churn using your churn prediction model.",
      from: "decima2",
    },
  ]);

  return (
    <ChatContext.Provider value={{ messages: state, dispatch }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => useContext(ChatContext);
