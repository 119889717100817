import React, { createContext, useReducer, useContext } from "react";

export const CollectionContext = createContext();

const initialState = [
  {
    id: 1,
    name: 'Age',
    segments: ['18.0 >= Age < 26.0', '26.0 >= Age < 35.0', '35.0 >= Age < 42.0',"50.0 >= Age < 65.0",],
    selected: true,
  },
  {
    id: 2,
    name: 'Gender',
    segments: ['Male', 'Female', 'Other'],
    selected: false,
  },
];

const collectionReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_COLLECTION':
      return [...state, action.payload];
    case 'EDIT_COLLECTION':
      return state.map((collection) =>
        collection.id === action.payload.id
          ? { ...collection, ...action.payload.data }
          : collection
      );
    case 'DELETE_COLLECTION':
      return state.filter((collection) => collection.id !== action.payload.id);
    case 'SET_SELECTED_COLLECTION':
      return state.map((collection) =>
        collection.id === action.payload.id
          ? { ...collection, selected: true }
          : { ...collection, selected: false }
      );
    default:
      return state;
  }
};

export const CollectionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(collectionReducer, initialState);
  return (
    <CollectionContext.Provider value={{ collections: state, dispatch }}>
      {children}
    </CollectionContext.Provider>
  );
};

export const useCollection = () => useContext(CollectionContext);
