import React, { useState, useEffect } from "react";
import { auth } from "../firebase";
import { getApiKey } from "../utils/ApiHandler";
import UserPortalsTable from "./UserPortalsTable";
import Login from "./Login";
import UserPortalCreation from "./UserPortalCreation"; // Ensure you have this component
import "./Demo.css";

const Demo = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [isLoading, setIsLoading] = useState(true); // Add a loading state

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setIsAuthenticated(true);
        try {
          const fbToken = await user.getIdToken();
          console.log("Token: ");
          const fetchedApiKey = await getApiKey(fbToken);
          setApiKey(fetchedApiKey);
        } catch (error) {
          console.error("Error fetching API key:", error);
        }
      } else {
        setIsAuthenticated(false);
      }
      setIsLoading(false); // Set loading to false after checking authentication and fetching API key
    });
    return () => unsubscribe();
  }, []);

  if (isLoading) {
    return (
      <div className="demo">
        <p>Loading...</p>
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <div className="demo">
        <Login />
      </div>
    );
  }

  if (!apiKey) {
    return (
      <div className="demo">
        <p>Fetching API key...</p>
      </div>
    );
  }

  return (
    <div className="demo">
      <div className="col-md-11 mx-auto">
        <h1>Hello, welcome to the Decima2 hub</h1>
        <p>
          Here you can monitor how your user portals have increased model
          accuracy and create new ones
        </p>
        <div className="row">
          <UserPortalsTable />
        </div>
        <div className="row">
          <UserPortalCreation />
        </div>
      </div>
    </div>
  );
};

export default Demo;
