import React from "react";

const userPortalsData = [
  {
    modelName: "Model A",
    datasetName: "Dataset A",
    userPortalURL: "/userportal",
    modelAccuracy: 81,
    modelAccuracyChange: 2.6,
    outstandingFeedback: true,
    totalInteractions: 150,
  },
  // Add more data as needed
];

const UserPortalsTable = () => {
  return (
    <div className="demo-container">
      <h1>User Portals</h1>
      <h2>Available User Portals to interact with predictive models</h2>
      <p>Click on the portal URL to access the user portal</p>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Model Name</th>
            <th>Dataset Name</th>
            <th>User Portal URL</th>
            <th>Model Accuracy</th>
            <th>Synthetic Data Available</th>
            <th>Total Interactions today</th>
          </tr>
        </thead>
        <tbody>
          {userPortalsData.map((portal, index) => (
            <tr key={index}>
              <td>{portal.modelName}</td>
              <td>{portal.datasetName}</td>
              <td>
                <a
                  href={portal.userPortalURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {portal.userPortalURL}
                </a>
              </td>
              <td>
                {portal.modelAccuracy}%
                <span style={{ color: "green", marginLeft: "5px" }}>
                  &#9650; {portal.modelAccuracyChange}%
                </span>
              </td>
              <td>{portal.outstandingFeedback ? "Yes" : "No"}</td>
              <td>{portal.totalInteractions}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserPortalsTable;
