import React, { useState, useEffect } from "react";
import Sidebar from "./SideBar.js";
import ChatPortal2 from "./ChatPortal2.js";
import Insights from "./Insights.js";
import InterventionPlanner from "./InterventionPlanner.js";
import { useChat } from "./ChatContext";
import CollectionManager from "./CollectionManager.js";

const UserPortal2 = () => {
  const [selectedTool, setSelectedTool] = useState("");
  const { dispatch } = useChat(); // Access the chat context

  // Update messages when selectedTool changes
  useEffect(() => {
    if (selectedTool === "insights") {
      dispatch({
        type: "ADD_MESSAGE",
        payload: {
          text: "Understand why customers churn by choosing segments and uncover meaningful patterns.",
          from: "decima2",
        },
      });
    } else if (selectedTool === "intervention-planner") {
      dispatch({
        type: "ADD_MESSAGE",
        payload: {
          text: "Plan actions to address churn effectively.",
          from: "decima2",
        },
      });
    }
  }, [selectedTool, dispatch]);

  const renderContent = () => {
    switch (selectedTool) {
      case "insights":
        return <Insights />;
      case "intervention-planner":
        return <InterventionPlanner />;
        case "manage-segments":
            return <CollectionManager />;
      default:
        return null;
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
          <Sidebar setSelectedTool={setSelectedTool} />
        
        <div className="col-md-3">
          <ChatPortal2 />
        </div>
        <div className="col-md-7">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default UserPortal2;
