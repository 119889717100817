import React from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import NavBar from './NavBar';
import Home from './home/Home';
import Demo from './demo/Demo';
import Docs from './docs/Docs';
import Login from './demo/Login';
import Blog from './blog/Blog';
import BlogPost from './blog/BlogPost';
import About from './home/About';
import UserPortal2 from './demo/userportal/UserPortal2';
import { ChatProvider } from './demo/userportal/ChatContext'; // Import ChatProvider
import { CollectionProvider } from './demo/userportal/CollectionContext'; // Import ChatProvider

import './App.css';

const App = () => {

  return (
    <ChatProvider> {/* Wrap the Router with ChatProvider */}
    <CollectionProvider>
          <Router>
            <NavBar />
            <div className="main-content">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/demo" element={<Demo />} />
                <Route path="/login" element={<Login />} />
                <Route path="/docs" element={<Docs />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/about" element={<About />} />
                <Route path="/blog/:post" element={<BlogPostWrapper />} />
                <Route path="/userportal" element={<UserPortal2 />} />
              </Routes>
            </div>
          </Router>
          </CollectionProvider>
    </ChatProvider>
  );
};

const BlogPostWrapper = () => {
  const { post } = useParams();
  console.log(`Post: ${post} type: ${typeof post}`);
  return <BlogPost post={post} />;
};

export default App;