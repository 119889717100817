import React, { useContext } from 'react';
import { CollectionContext } from './CollectionContext';

const CollectionManager = () => {
  const { collections, dispatch } = useContext(CollectionContext);

  const selectCollection = (selectedCollection) => {
    dispatch({ type: 'SET_SELECTED_COLLECTION', payload: { id: selectedCollection.id } });
  };

  return (
    <div>
      {collections.map((collection) => (
        <div key={collection.id}>
          <span>
            {collection.name} {collection.selected ? '(selected)' : ''}
          </span>
          {!collection.selected && (
            <button onClick={() => selectCollection(collection)}>Select</button>
          )}
          <ul>
            {collection.segments.map((segment, index) => (
              <li key={index}>{segment}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default CollectionManager;