import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "./Home.css"; // Ensure you import the CSS file

import IndustryConversation from "./IndustryConversation";
import WaitingList from "./WaitingList";
import FAQ from "./FAQ";

const Home = () => {
  return (
    <div className="home">
      <div className="container-fluid p-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8 text-left">
              <h1 className="display-4">Optimise customer outcomes</h1>
              <h2 className="lead">Understand the most effective customer interventions with causal AI</h2>
            </div>
            <div className="col-md-4 text-center">
              <img src="img/demo.gif" alt="Demo GIF" className="img-fluid demo-gif" />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container my-5">
        <div className="notice-box p-4 text-center">
          <p>Read how the effective interventions and customer insights are made with telecom customer data <a href="https://medium.com" target="_blank" rel="noopener noreferrer">here</a>.</p>
        </div>
      </div> */}

      <div className="container my-5">
        <div className="row">
          <div className="col-md-4 text-center">
            <div className="benefit-box p-4">
              <h3>Explore customer patterns</h3>
              <p>Understand why your customers churn</p>
              <img src="img/intervention.png" alt="Benefit 1" className="img-fluid mt-3" />
            </div>
          </div>
          <div className="col-md-4 text-center">
            <div className="benefit-box p-4">
              <h3>Find optimal outcomes</h3>
              <p>Simulate and optimise how actions affect customer segments</p>
              <img src="img/influential.png" alt="Benefit 2" className="img-fluid mt-3" />
            </div>
          </div>
          <div className="col-md-4 text-center">
            <div className="benefit-box p-4">
              <h3>Integrate with your favourite tools</h3>
              <p>Automatically implement optimal retention actions</p>
              <img src="img/simulation.png" alt="Benefit 3" className="img-fluid mt-3" />
            </div>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div className="row">
          <div className="col-md-4 text-center">
            <div className="benefit-box p-4">
              <h3>Causal Model</h3>
              <p>Our technology is informed by cutting edge PhD research, a novel generative model learns complex patterns in data</p>
              <img src="img/causalModel.png" alt="Benefit 1" className="img-fluid mt-3 small-logo" />
            </div>
          </div>
          <div className="col-md-4 text-center">
            <div className="benefit-box p-4">
              <h3>Synthetic data</h3>
              <p>The causal model accurately generates quality synthetic data</p>
              <img src="img/syntheticData.png" alt="Benefit 2" className="img-fluid mt-3 small-logo" />
            </div>
          </div>
          <div className="col-md-4 text-center">
            <div className="benefit-box p-4">
              <h3>Counterfactual interventions</h3>
              <p>The synthetic data simulates what if scenarios to understand the impact of actions</p>
              <img src="img/counterFactualInterventions.png" alt="Benefit 3" className="img-fluid mt-3 small-logo" />
            </div>
          </div>
        </div>
      </div>

      <FAQ />
      <WaitingList />
    </div>
  );
};

export default Home;
