import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const APIKeyRenderer = ({ apiKey }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(apiKey);
    setShowToast(true);
    setTimeout(() => setShowToast(false), 2000);
  };

  return (
    <div>
      <h2>Your API Key</h2>
      <p>Use this to upload models and datasets from scripts</p>
      <div className="input-group">
        <input
          type={isVisible ? "text" : "password"}
          value={apiKey}
          readOnly
          className="form-control"
          style={{
            WebkitTextSecurity: isVisible ? "none" : "disc",
            width: "200px",
            padding: "5px",
            fontSize: "14px",
          }}
        />
        <button className="btn btn-secondary" onClick={copyToClipboard}>
          Copy API Key
        </button>
        <button className="btn btn-primary" onClick={toggleVisibility}>
          {isVisible ? "Hide API Key" : "Show API Key"}
        </button>
      </div>
      {showToast && (
        <div
          className="toast show position-fixed bottom-0 end-0 p-3"
          style={{ zIndex: 11 }}
        >
          <div className="toast-body">API key copied to clipboard</div>
        </div>
      )}
    </div>
  );
};

export default APIKeyRenderer;
